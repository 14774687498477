import React from "react";
import {carouselData} from "./carouselData";
import parse from "html-react-parser";
import starTrail from "../../assets/img/minuteman.jpeg"
import {Swiper, SwiperSlide}  from "swiper/react";
import {Pagination} from "swiper";
import {MatchMediaBreakpoints, MatchBreakpoint } from "react-hook-breakpoints";
import 'swiper/css';
import 'swiper/css/pagination';
import "./CarouselStyles.scss";

const breakpoints = {
    small: 999,
    large: 1000
}
const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<div class="' + className + '"><svg class="circular-loader" viewBox="25 25 50 50"><circle class="loader-path" cx="50" cy="50" r="20" fill="none"/></svg>' + '' + "</div>";
    },
  };

export const CarouselComponent = () => {
    return (
        <MatchMediaBreakpoints breakpoints={breakpoints}>
                <MatchBreakpoint is="small">
                    <div className="listRoot-temp">

                    </div>
                    {/* <div className="listRoot">
                        {
                            carouselData.map((elem, i) => (
                                <div key = {i} className="listItem">
                                    <Item img={elem.img} title={elem.title} subtitle={elem.subTitle} description={elem.description} link={elem.link} linkName={elem.linkName} />
                                </div>
                            ))
                        }
                    </div> */}
                </MatchBreakpoint>
                {/* <MatchBreakpoint min="large">
                <div className="carouselRoot">
                    <div id='swiperContainer'>
                        <Swiper speed={600} modules={[Pagination]} pagination={pagination}>
                            {carouselData.map((elem, i) => (
                                <SwiperSlide key={i}>
                                    <CarouselCard 
                                        img={elem.img} 
                                        title={elem.title} 
                                        subtitle={elem.subTitle} 
                                        description={elem.description} 
                                        link={elem.link} 
                                        linkName={elem.linkName} 
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
                </MatchBreakpoint> */}
        </MatchMediaBreakpoints>
    )
}


const CarouselCard = ({img, title, subtitle = "", description, link = "", linkName = ""}) => {
    let subtitleElement = <></>
    if (subtitle.length > 0) {
        subtitleElement =  
            <h3 id='cardSubtitle'>
                {subtitle}
            </h3>
    }
    return (
        <div className="cardRoot">
            <div className="cardImage" >
                <img className="image-holder" src={img} />
            </div>
            <div className="cardContainer">
                <h2 id='cardTitle'>
                    {title}
                </h2>
                {subtitleElement}
                <p id='cardDescription'>
                    {parse(description)}
                </p>
                <div>
                    <a className="linkText" target={"_blank"} rel="noopener" href={link}>{linkName}</a>
                </div>
            </div>
        </div>
    )
}

const Item = ({img, title, subtitle = "", description, link = "", linkName = ""}) =>  {
    return (
        <div className="itemRoot">
            <div className="itemImage">
                <img className="item-image-holder" src={img} width="100%" height="auto" />
            </div>
            <div className="itemDescription">
                <div style={{fontSize: "34px", marginBottom: "10px"}}>
                    {title}
                </div>
                <div style={{marginBottom: "10px", fontSize: "22px"}}>{subtitle}</div>
                <div style={{marginBottom: "10px"}}>{parse(description)}</div>
                <div>
                    <a className="linkText" target={"_blank"} rel="noopener" href={link}>{linkName}</a>
                </div>
            </div>

        </div>
    )
}
